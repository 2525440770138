import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import Menu from "../components/common/Menu";
import TattooGallery from "../components/tattooparler/Gallery";
import "../css/tattooparlor.css";

const TattooParlor = ({ data }) => {
  const tattooParlor = data.sanityPage.tattooTemp;
  return (
    <div className="tatoo_parlour tatoo_parlour_bg">
      <div className="container">
        <div className="row header_menu">
          <div className="col-lg-3 col-md-3 col-sm-3 col-3">
            <Link to="/">
              <GatsbyImage
                image={tattooParlor.back.asset.gatsbyImageData}
                alt={tattooParlor.back.asset.altText || "back"}
                loading="eager"
                className="img-fluid tatoo_parlour1"
              />
            </Link>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
            <Link to="/">
              <center>
                <GatsbyImage
                  image={tattooParlor.logo.asset.gatsbyImageData}
                  alt={tattooParlor.logo.asset.altText || "logo"}
                  loading="eager"
                  className="img-fluid tatoo_parlour1_1"
                />
              </center>
            </Link>
            <div className="parlour_1">
              <center>
                <GatsbyImage
                  image={tattooParlor.tatto.asset.gatsbyImageData}
                  alt={tattooParlor.tatto.asset.altText || "tattoo"}
                  loading="eager"
                  className="img-fluid tatoo_parlour2"
                />
              </center>
            </div>
            <div className="parlour_2">
              <center>
                <GatsbyImage
                  image={tattooParlor.parlor.asset.gatsbyImageData}
                  alt={tattooParlor.parlor.asset.altText || "tattoo parlor"}
                  loading="eager"
                  className="img-fluid tatoo_parlour3"
                />
              </center>
            </div>
            <div className="parlour_3">
              <center>
                <GatsbyImage
                  image={tattooParlor.line.asset.gatsbyImageData}
                  alt={tattooParlor.line.asset.altText || "tattoo cut lines"}
                  loading="eager"
                  className="img-fluid tatoo_parlour_4"
                />
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-3">
            {/*Burger Menu Start-*/}
            <Menu />
            {/*Burger Menu End-*/}
          </div>
        </div>
        <div className="clearfix" />
        <TattooGallery tattoos={tattooParlor.tattooList} />
        <div className="clearfix" />
        {/* <div className="row">
          <div className="col-lg-12 col-md-12">
            <center>
              <StaticImage
                src="https://www.matthewgraygubler.com/images/tattoo-parlor-img/gubler-email.png"
                alt="gubler email"
                className="img-fluid tatoo_parlour132"
                loading="lazy"
                placeholder="blurred"
                layout="fullWidth"
              />
            </center>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    sanityPage(slug: { current: { eq: "/tattoo-parlor" } }) {
      title
      template
      slug {
        current
      }
      tattooTemp {
        back {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        logo {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        tatto {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        parlor {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        line {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        tattooList {
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
        }
      }
    }
  }
`;

export default TattooParlor;
