import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const TattooGallery = ({ tattoos }) => {
  return (
    <div className="row">
      {tattoos.map((tattoo, index) => (
        <div className="col-6 col-md-3 tattoo_parler" key={index}>
          <center>
            <GatsbyImage
              image={tattoo.image.asset.gatsbyImageData}
              alt={tattoo.image.asset.altText || "tattoo"}
              className="img-fluid "
              loading="lazy"
              objectFit="cover"
            />
          </center>
          <br />
        </div>
      ))}
    </div>
  );
};

export default TattooGallery;
